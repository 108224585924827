<template>
  <v-container fill-height fluid>
    <v-layout fill-width>
      <v-flex>
        <v-card class="mt-10">
          <v-card-title class="headline"
            ><span>Schichtplanung</span>
            <v-btn-toggle class="ml-8" v-model="type" borderless>
              <v-btn value="day">
                <v-icon left> mdi-calendar-today </v-icon>
                <span class="hidden-sm-and-down">Tag</span>
              </v-btn>
              <v-btn value="week">
                <v-icon left> mdi-calendar-range </v-icon>
                <span class="hidden-sm-and-down">Woche</span>
              </v-btn>
              <v-btn value="month">
                <v-icon left> mdi-calendar-text</v-icon>
                <span class="hidden-sm-and-down">Monat</span>
              </v-btn>
            </v-btn-toggle>
            <div style="position: absolute; right: 30px">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="
                      dialog = true;
                      newEvent.mode = 'new';
                    "
                    fab
                    color="success"
                  >
                    <i class="material-icons">add</i>
                  </v-btn>
                </template>
                <span>Neuen Eintrag erstellen</span>
              </v-tooltip>
            </div>
          </v-card-title>

          <v-card-text>
            <v-sheet tile height="54" class="d-flex mb-6">
              <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <!--  <v-select
                v-model="type"
                :items="types"
                dense
                outlined
                hide-details
                item-text="label"
                item-value="value"
                class="ma-2"
                label="Ansicht"
              ></v-select> -->
              <v-select
                v-model="category"
                :items="eventtypes"
                dense
                outlined
                hide-details
                clearable
                item-text="label"
                item-value="value"
                class="ma-2"
                @change="filterEvents"
                label="Kategorie"
              ></v-select>
              <v-select
                v-model="user"
                :items="users"
                dense
                clearable
                outlined
                hide-details
                item-text="name"
                item-value="id"
                class="ma-2"
                @change="filterEvents"
                label="Mitarbeiter"
              ></v-select>
              <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet>
              <v-calendar
                ref="calendar"
                v-model="value"
                :first-interval="14"
                :interval-minutes="30"
                :interval-count="24"
                :type="type"
                :weekdays="weekdays"
                :events="events"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                :event-ripple="false"
                @click:event="showEvent"
              >
                <template v-slot:event="{ event, timed, eventSummary }">
                  <div class="v-event-draggable" v-html="eventSummary()"></div>
                  <div
                    v-if="timed"
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                  ></div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Neuen Eintrag erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="newEvent.name"
                    label="Title*"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    label="Start-Datum *"
                    :timePickerProps="timeprops"
                    :datePickerProps="datePickerProps"
                    v-model="newEvent.start"
                    required
                    :rules="[rules.required]"
                  >
                  </v-datetime-picker>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    label="End-Datum *"
                    :timePickerProps="timeprops"
                    :datePickerProps="datePickerProps"
                    v-model="newEvent.end"
                    required
                    :rules="[rules.required]"
                  >
                  </v-datetime-picker>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    v-model="newEvent.type"
                    :items="eventtypes"
                    dense
                    outlined
                    hide-details
                    item-text="label"
                    item-value="value"
                    class="ma-2"
                    label="Kategorie"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    v-model="newEvent.userid"
                    :items="users"
                    dense
                    outlined
                    hide-details
                    item-text="name"
                    item-value="id"
                    class="ma-2"
                    label="Mitarbeiter"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    v-model="newEvent.color"
                    :items="colors"
                    dense
                    outlined
                    hide-details
                    class="ma-2"
                    label="Farbe"
                  >
                    <template slot="item" slot-scope="data">
                      <v-icon :color="data.item">circle</v-icon> {{ data.item }}
                    </template>
                  </v-select>
                </v-flex>

                <v-flex xs12 sm12 md12 v-if="newEvent.mode === 'edit'">
                  <hr class="my-3" />
                  <v-btn color="error" @click="deleteEvent()"
                    >Event Löschen</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              v-if="newEvent.mode === 'new'"
              text
              @click="createNewEvent()"
              >Erstellen</v-btn
            >
            <v-btn color="success" v-else text @click="updateEvent()"
              >Aktualisieren</v-btn
            >
            <v-btn
              color="error"
              text
              @click="
                dialog = false;
                edit = false;
              "
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    type: "week",
    category: "",
    dialog: false,
    newEvent: {},
    timeprops: {
      format: "24hr",
    },
    datePickerProps: {
      "first-day-of-week": "1",
    },
    eventtypes: [
      {
        label: "Montage",
        value: "montage",
      },
      {
        label: "Wartung",
        value: "wartung",
      },
      {
        label: "Service",
        value: "service",
      },
    ],
    types: [
      {
        label: "Monat",
        value: "month",
      },
      {
        label: "Woche",
        value: "week",
      },
      {
        label: "Tag",
        value: "day",
      },
    ],
    value: "",
    colors: [
      "#FF0000",
      "#00FF00",
      "#0000FF",
      "#FFFF00",
      "#FF00FF",
      "#00FFFF",
      "#673AB7",
      "#FF9800",
      "#757575",
    ],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    team: "",
    teams: [],
    user: "",
    users: [],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    value: "",
    unfilteredEvents: [],
    events: [],
    rules: {
      required: (value) => !!value || "Dieses Feld ist erforderlich.",
    },
  }),

  mounted() {
    this.getEvents();
  },
  methods: {
    getEventColor(event) {
      if (event.color) {
        return event.color;
      }
      if (event.props) {
        if (event.props && event.props.color) {
          return event.props.color;
        } else {
          return JSON.parse(event.props).color;
        }
      }
      return "red";
    },
    filterEvents() {
      const userid = this.user;
      const cat = this.category;
      const events = [];
      if (!userid && !cat) {
        this.events = this.unfilteredEvents;
        return;
      }
      if (userid && cat) {
        this.unfilteredEvents.forEach((evt) => {
          if (evt.userid === userid && evt.type === cat) {
            events.push(evt);
          }
        });
        this.events = events;
        return;
      }
      if (userid) {
        this.unfilteredEvents.forEach((evt) => {
          if (evt.userid === userid) {
            events.push(evt);
          }
        });
        this.events = events;
        return;
      }
      if (cat) {
        this.unfilteredEvents.forEach((evt) => {
          if (evt.type === cat) {
            events.push(evt);
          }
        });
        this.events = events;
        return;
      }
    },
    createNewEvent() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const event = this.newEvent;
      const formData = new FormData();
      const createEvent = {
        name: event.name,
        userid: event.userid,
         start: this.$formatDateTimeISO(event.start),
        end: this.$formatDateTimeISO(event.end), 
        type: event.type,
        color: event.color,
      };
      formData.append("mode", "new");
      formData.append("json", JSON.stringify(createEvent));
      this.$http({
        method: "post",
        url: "editSchichtplanung.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.status === 200 && response.data && response.data.id) {
            createEvent.id = response.data.id;
            this.events.push(createEvent);
            this.dialog = false;
            const msg = "Das Event wurde erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Das Event konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const msg =
            "Das Event konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    updateEvent() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const event = this.newEvent;
      const createEvent = {
        name: event.name,
        id: event.id,
        userid: event.userid,
        start: this.$formatDateTimeISO(event.start),
        end: this.$formatDateTimeISO(event.end),
        type: event.type,
        color: event.color,
      };
      const formData = new FormData();
      formData.append("mode", "edit");
      formData.append("json", JSON.stringify(createEvent));
      this.$http({
        method: "post",
        url: "editSchichtplanung.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (
            response.status === 200 &&
            response.data &&
            response.data.success
          ) {
            let index = this.events.findIndex((e) => e.id === event.id);
            if (index > -1) {
              this.events.splice(index, 1);
              this.events.push(createEvent);
              this.dialog = false;
              const msg = "Das Event wurde erfolgreich aktualisiert.";
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
            } else {
              const msg =
                "Das Event konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
            }
          } else {
            const msg =
              "Das Event konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const msg =
            "Das Event konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    deleteEvent() {
      const formData = new FormData();
      formData.append("id", this.newEvent.id);
      this.$http({
        method: "post",
        url: "deleteSchichtplanung.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (
            response.status === 200 &&
            response.data &&
            response.data.success
          ) {
            let index = this.events.findIndex((e) => e.id === this.newEvent.id);
            if (index > -1) {
              this.events.splice(index, 1);
              this.dialog = false;
              const msg = "Das Event wurde erfolgreich gelöscht.";
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
            } else {
              const msg =
                "Das Event konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
            }
          } else {
            const msg =
              "Das Event konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const msg =
            "Das Event konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else {
        this.dialog = true;
        this.newEvent.start = this.roundTime(mouse);
      }
    },
    showEvent({ nativeEvent, event }) {
      const newEvent = JSON.parse(JSON.stringify(event));
      newEvent.mode = "edit";
      newEvent.start = new Date(newEvent.start);
      newEvent.end = new Date(newEvent.end);
      this.newEvent = newEvent;
      this.dialog = true;
      nativeEvent.stopPropagation();
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    /* getEvents({ start, end }) {
       const events = [];

      const min = new Date(`${start.date}T00:00:00`).getTime();
      const max = new Date(`${end.date}T23:59:59`).getTime();
      const days = (max - min) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const timed = this.rnd(0, 3) !== 0;
        const firstTimestamp = this.rnd(min, max);
        const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
        const start = firstTimestamp - (firstTimestamp % 900000);
        const end = start + secondTimestamp;

        events.push({
          name: this.rndElement(this.names),
          color: this.rndElement(this.colors),
          start,
          end,
          timed
        });
      }

      this.events = events; 
    }, */
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    getEvents() {
      this.$http({
        method: "post",
        url: "getSchichtplanungen.php",
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success && response.data.data) {
            if (response.data.users) {
              this.users = response.data.users;
            }
            if (response.data.data) {
              this.unfilteredEvents = response.data.data;
              this.events = response.data.data;
             /*  this.events = [
                {
                  color: "#00FF00",
                  companyid: "2",
                  created_at: "2021-11-30 15:37:06",
                  created_by: "50",
                  end: "2021-11-30 15:37:06",
                  id: "4",
                  name: "Start Industries Montage",
                  start: "2021-11-30 15:37:06",
                  type: "montage",
                  updated_at: "2021-11-30 15:37:06",
                  updated_by: "50",
                  userid: "64",
                  username: "Andreas Klier",
                },
              ];
 */
              //  this.events = response.data.data;
              /*  this.events[1].start = parseInt(this.events[1].start);
               this.events[1].end = parseInt(this.events[1].end); */

              /* const min = new Date(`2021-10-18T00:00:00`).getTime();
              const max = new Date(`2021-10-18T23:59:59`).getTime();
              const days = (max - min) / 86400000;
              const eventCount = this.rnd(days, days + 20);
              const events = [];
              for (let i = 0; i < eventCount; i++) {
                const timed = this.rnd(0, 3) !== 0;
                const firstTimestamp = this.rnd(min, max);
                const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
                const start = firstTimestamp - (firstTimestamp % 900000);
                const end = start + secondTimestamp;

                events.push({
                  name: "test",
                  color: this.rndElement(this.colors),
                  start,
                  end,
                  timed,
                }); */
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>